import { Controller } from "@hotwired/stimulus"

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for selectable
import esLocale from '@fullcalendar/core/locales/es';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import { get, put } from '@rails/request.js'

import Sticky from 'sticky-js';
import TomSelect from "tom-select"

import * as bootstrap from "bootstrap"
import moment from 'moment-timezone';
import $ from "jquery"
import _ from 'lodash'
const log = console.log

export default class extends Controller {
  
  static values = {
    canEdit: Boolean
  }
  
  refresh() {
    if (this.isDragging == true) return false
    // log('calendar#refresh')
    this.calendar.refetchEvents()
  }
  
  disconnect() {
    this.calendar.destroy()
    // log('calendar#disconnect')
    document.removeEventListener('turbo:submit-end', this.boundOnSubmitEnd)
    clearInterval(this.refreshInterval)
    if (this.filter_staff) this.filter_staff.tomselect.destroy()
    if (this.filter_contact) this.filter_contact.tomselect.destroy()
    this.sticky.destroy()
  }
  
  onSubmitEnd(e) {
    if (_.includes(e.detail.fetchResponse.response.url, 'app/events')) {
      this.refresh()
      // log('turbo:submit-end (events)')
    }
  }
  
  async newEvent(date) {
    await get(`/${window.locale}/app/events/new?d=${date}`, {
      responseKind: 'turbo-stream'
    })
  }
  
  convertHourTo12 (time) {
    if (window.hour_format == '24') return time;
    // log(time)
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { // If time format correct
      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join (''); // return adjusted time or original string
  }
  
  connect() {
    this.refreshInterval = setInterval(this.refresh.bind(this), 10000)
    
    let self = this
    this.selected_contact_id = null
    this.selected_staff_id = null
    
    if (this.canEditValue) {
    
      this.new_button = $('button#new-event')
      this.new_button.on('click', function(){
        self.newEvent(moment.tz(user_timezone).startOf('hour').format())
      })
      
      this.filter_contact = $('#filter_contact')[0]
      new TomSelect(this.filter_contact,{
        plugins: {
          remove_button:{
            title:'Remove this item',
          }
        }
      })
      
      this.filter_contact.addEventListener('change', function(){
        self.selected_contact_id = $(this).val()
        self.refresh()
      })
      
      this.filter_staff = $('#filter_staff')[0]
      new TomSelect(this.filter_staff,{
        plugins: {
          remove_button:{
            title:'Remove this item',
          }
        }
      })
        
      this.filter_staff.addEventListener('change', function(){
        self.selected_staff_id = $(this).val()
        self.refresh()
      })
      
    }
    
    this.boundOnSubmitEnd = this.onSubmitEnd.bind(this)
    document.addEventListener('turbo:submit-end', this.boundOnSubmitEnd)
    
    this.calendar = new Calendar($(this.element).find('#calendar')[0], {
      eventSources: [
        {
          url: `/${window.locale}/app/events.json`,
          extraParams: function(){
            return {
              contact_id: self.selected_contact_id,
              staff_id: self.selected_staff_id,
            }
          }
        }
      ],
      plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrap5Plugin ],
      initialView: 'timeGridWeek',
      timeZone: user_timezone,
      // selectable: false, 
      editable: (this.canEditValue), 
      eventDurationEditable: false,
      nowIndicator: true,
      now: moment.tz(user_timezone).format(), 
      height:'auto',
      locale: (window.locale == 'es' ? esLocale : 'en'),
      allDaySlot: false,
      themeSystem: 'bootstrap5',
      stickyHeaderDates: false,
      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        omitZeroMinute: false,
        meridiem: 'short',
        hour12: (window.hour_format == '12')
      },
      headerToolbar: {
        start: 'title',
        center: 'dayGridMonth,timeGridWeek',
        end: 'prev,next today'
      },
      dayHeaderFormat: { weekday: 'short', day: 'numeric', omitCommas: true },
      eventDragStart: function(info) {
        self.isDragging = true
      },
      eventDragStop: function(info) {
        self.isDragging = false
      },
      dayHeaderContent: function(arg) {
        let s = arg.text.split(' ') 
        let span = document.createElement('span')
        span.innerHTML = `${s[0]} <span>${s[1]}</span>`
        let arrayOfDomNodes = [ span ]
        return { domNodes: arrayOfDomNodes }
      },
      eventClick: async function(info) {
        if (self.canEditValue) {
          await get(`/${window.locale}/app/events/${info.event.id}/edit`, {
            responseKind: 'turbo-stream'
          })
        } else {
          await get(`/${window.locale}/app/events/${info.event.id}`, {
            responseKind: 'turbo-stream'
          })
        }
      },
      dateClick: function(info) {
        if (self.canEditValue) self.newEvent(moment.tz(info.dateStr, user_timezone).format())
      },
      eventDrop: async function(info) {
        // fix del timezone (calendar le suma +2)
        var zone = moment.tz.zone(user_timezone);
        var offset = zone.parse(new Date());
        let date = moment(info.event.start).add(offset,"minutes").format()
        
        await put(`/${window.locale}/app/events/${info.event.id}`, {
          body: JSON.stringify({date}),
          responseKind: 'application/json'
        })
      }
    });
    this.calendar.render()
    
    // $('header .brand').addClass('sticky')
    // $('header .brand').attr('data-sticky-wrap', true)
    // $('header .brand').attr('data-sticky-class', 'stuck')
    
    $('.fc-header-toolbar').addClass('sticky')
    $('.fc-header-toolbar').attr('data-sticky-wrap', true)
    $('.fc-col-header').addClass('sticky')
    $('.fc-col-header').attr('data-margin-top', 88)
    $('.fc-col-header').attr('data-sticky-wrap', true)
    this.sticky = new Sticky('.sticky');
    
    $(document).on({
        mouseenter: function() {
          let cellWidth = $('th.fc-col-header-cell').width();
          let cellHeight = $(this).height();
          let columnCount = $('thead table.fc-col-header th.fc-col-header-cell').children().length;
          
          if (!$(this).html()) {
              for (var i = 0; i < columnCount; i++) {
                  $(this).append('<td class="temp-cell" style="border:0px;"></td>');
              }
          }
          $(this).children('td').each(function() {
            $(this).css('width', (cellWidth + 3))
            $(this).css('height', (cellHeight - 1))
              $(this).hover(function() {
                  let dtime = $(this).parent().data('time').slice(0, -3);
                  $(this).html('<div class="fc-current-time">' + self.convertHourTo12(dtime) + '</div>');
              }, function() {
                  $(this).html('');
              });
          });
        },
        mouseleave: function() {
          $(this).children('.temp-cell').remove();
        }

    }, 'td.fc-timegrid-slot.fc-timegrid-slot-lane');
    
  }
}
