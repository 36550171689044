import { Controller } from "@hotwired/stimulus"
const log = console.log

import flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

export default class extends Controller {
 
  disconnect() {
    this.instance.destroy()
  }
 
  connect() {
    this.instance = flatpickr(this.element, {
      dateFormat: "Y-m-d",
      altInput: true,
      altFormat: "l j M Y",
      disableMobile: true,
      locale: (window.locale === 'es' ? Spanish : null),
      // minDate: "today",
    });
  }
  
}
