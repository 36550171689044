import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
import flatpickr from "flatpickr";
import $ from "jquery"
import { Spanish } from "flatpickr/dist/l10n/es.js"
import moment from 'moment-timezone';
import 'moment/locale/es';
import _ from 'lodash';
const log = console.log
import Pickr from '@simonwep/pickr';
import '@simonwep/pickr/dist/themes/nano.min.css';      // 'nano' theme
import TomSelect from "tom-select"
import { get } from '@rails/request.js'

moment.locale('es');

// Connects to data-controller="event-modal-form"
export default class extends Controller {
  
  static targets = ['date']
  
  async onTurboSubmit(e) {
    let res = e.detail.fetchResponse.response
 
    if (_.includes(res.url, 'app/clients')) {
      console.log('turbo:submit-end (clients)')
      if (res.status == 200) {
        
        let res = await get(`/${window.locale}/app/clients.json?order=id+desc&per_page=1`, {
          responseKind: 'json'
        })
        let data = await res.json
        let client = data[data.length-1]
        this.client_select.addOption({value: client.id, text: `${client.firstname} ${client.lastname}`})
        this.client_select.refreshOptions(false)
        this.client_select.addItem(client.id)
        
      }
    }
    
    if (_.includes(res.url, 'app/services')) {
      console.log('turbo:submit-end (services)')
      if (res.status == 200) {
        
        let res = await get(`/${window.locale}/app/services.json?order=id+desc&per_page=1`, {
          responseKind: 'json'
        })
        let data = await res.json
        let service = data[data.length-1]
        this.service_select.addOption({value: service.id, text: `${service.full_title}`})
        this.service_select.refreshOptions(false)
        this.service_select.addItem(service.id)
        
      }
    }
    
    // console.log(e)
    // console.log(e.target)
    // if ($(e.target).hasClass('edit_event')) {
    //   this.refresh()  
    // }
  }
  
  setDate() {
    // console.log('setDate()')
    let time = $(this.time_picker_input).val()
    let date = $(this.date_picker_input).val()
    let s = `${date} ${time}`
    let time_with_zone = moment.tz(s, 'YYYY-MM-DD HH:mm', user_timezone).format()
    // log(s)
    // log(moment.tz(s, 'YYYY-MM-DD HH:mm', user_timezone).toISOString())
    // log(moment.tz(s, 'YYYY-MM-DD HH:mm', user_timezone).format())
    $(this.date_input).val(time_with_zone)
  }
  
  onTimeChange(e) {
    this.setDate()
  }
  
  connect() {
    const that = this
    this.date_input = this.dateTarget
    this.time_picker_input = $('#time_picker')[0]
    this.date_picker_input = $('#date_picker')[0]
    
    this.boundOnTimeChange = this.onTimeChange.bind(this)
    this.time_picker_input.addEventListener('change', this.boundOnTimeChange)
    
    this.boundOnTurboSubmit = this.onTurboSubmit.bind(this)
    document.addEventListener('turbo:submit-end', this.boundOnTurboSubmit)
        
    this.modal = new bootstrap.Modal(this.element, {})
    this.modal.show()
    
    this.date_picker = flatpickr("#date_picker:not(.flatpickr-input)", {
      dateFormat: "Y-m-d",
      // enableTime: true,
      // time_24hr: true,
      altInput: true,
      altFormat: "l j M Y",
      locale: (window.locale === 'es' ? Spanish : null),
      onChange: function(selectedDates, dateStr, instance) {
        that.setDate()
      }
    });
    
    if ($(this.element).find("select.pretty-select").length > 0) {
      $(this.element).find("select.pretty-select").each(function(i, el){
        new TomSelect(el,{
          maxOptions: null,
          plugins: {
            remove_button:{
              title:'Remove this item',
            }
          },
        })
      }.bind(this)) 
    }
    
    this.client_select = $('#event_client_id')[0].tomselect
    this.service_select = $('#event_service_id')[0].tomselect
    
    this.boundOnServiceChange = this.onServiceChange.bind(this)
    this.service_select.on('change', this.boundOnServiceChange)
    this.service_select.trigger('change')
    
    // color picker
    
    const pickr = Pickr.create({
      el: '#event_color_picker',
      theme: 'nano', // or 'monolith', or 'nano',
      swatches: window.event_colors,
      components: {
      },
      default: $('#event_color').val(),
      inline: true
    })
    pickr.on('change', (color, source, instance) => {
      pickr.applyColor()
    })
    pickr.on('save', (color, instance) => {
      $('#event_color').val(color.toHEXA().toString())
      pickr.hide()
    })
    
  }
  
  async onServiceChange(val) {
    if (!val) return false
    
    let res = await get(`/${window.locale}/app/services/${val}.json`, {
      responseKind: 'json'
    })
    let service = await res.json
    $('#event_duration').val(service.duration)
  }
  
  disconnect() {
    // console.log('disconnect')
    this.modal.hide()
    document.removeEventListener('turbo:submit-end', this.boundOnTurboSubmit)
    this.time_picker_input.removeEventListener('change', this.boundOnTimeChange)
    this.date_picker.destroy()
    
    if (this.service_select) this.service_select.off('change', this.boundOnServiceChange)
    $(this.element).find("select.pretty-select").each(function(i, el){
      if (el.tomselect) el.tomselect.destroy()
    })
  }
}
