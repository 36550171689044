import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"
import $ from "jquery"
const log = console.log

// Connects to data-controller="help-card"
export default class extends Controller {
  
  connect() {
    this.modal = new bootstrap.Modal(this.element, {
      backdrop: 'static'
    })
    this.modal.show()
    
    let cardId = $(this.element).data('card-id')
    
    $(this.element).find('[data-bs-dismiss]').on('click', function(){
      let tok = $('meta[name="csrf-token"]')[0].content
      fetch(`/${window.locale}/app/user_tips`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': tok
        },
        body: JSON.stringify({
          card_id: cardId
        })
      })
    })
  }
  
  disconnect() {
    log('modal disconnect')
    this.modal.dispose()
    $(this.element).find('[data-bs-dismiss]').off('click')
  }
  
}
