import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  connect() {
    let tt = new bootstrap.Toast(this.element, {
      delay: 6000
    })
    tt.show()
  }
}
