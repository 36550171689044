import { Controller } from "@hotwired/stimulus"
const log = console.log
import $ from "jquery"

export default class extends Controller {
  
  disconnect() {
    $(this.element).find('.list-down-btn').off('click')
    $(this.element).find('input[type=checkbox]').off('change')
  }
  
  connect() {
    $(this.element).find('.list-down-btn').on('click',function(event){
      event.preventDefault();
      var target = $(this).attr('data-toggle');
      $(target).toggle();
      var clicked = event.target;
      if (clicked.tagName === 'BUTTON') clicked = $(clicked).find('i').get()
      $(clicked).toggleClass(["fa-chevron-down", "fa-chevron-up"]);
    });
    
    $(this.element).find('input[type=checkbox]').on('change', function(){
      let checked = $(this).is(':checked')
      
      if (checked) {
        let p = $(this).parents('.list-group-item').get()[1]
        if (p) $(p).find('> div > input[type=checkbox]').prop('checked', true)
        $(this).closest('.list-group-item').find('input[type=checkbox]').prop('checked', true)
      } else {
        $(this).closest('.list-group-item').find('input[type=checkbox]').prop('checked', false)
      } 
      
    })
  }
  
}
